import { Box, Button, CircularProgress, Container, LinearProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import StepOne from './components/StepOne'
import StepThree from './components/StepThree'
import StepTwo from './components/StepTwo'
import { HomePageProvider, useHomePageContext } from './context/HomePage.context'
import { webApiService } from '../../services'
import { useSnackbar } from 'notistack'
import { getMonto } from '../../utils/getMonto'

import Safe from '../../assets/img/safe@2x.png'
import Phones from '../../assets/img/phones@2x.png'

const steps = ['Elige tu equipo actual', 'Evalúa tu equipo', '¡Casi listo! Ingresa tu email']

const HomePageContent: React.FC = () => {
  const { ctx, saveCtx } = useHomePageContext()
  const [activeStep, setActiveStep] = useState(0)
  const { enqueueSnackbar } = useSnackbar()
  const [buttons, setButtons] = useState({ loading: false, success: false })

  const handleRestart = async () => {
    console.log('Volviendo a comenzar...')
    window.location.reload()
  }

  const handleNext = async () => {
    if (activeStep < 2) {
      saveCtx({ ...ctx, steps: { isValid: false, current: activeStep + 1 } })
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    } else {
      setButtons({ ...buttons, loading: true })
      let code = ''

      // Guardamos la información
      try {
        code = ctx.data.sbu.id_sbu === 1 ? await webApiService.createLoginCodeAufbau().then(res => res.data) : null

        const obj = {
          id_sbu: ctx.data.sbu.id_sbu,
          sbu: ctx.data.sbu.name,
          brand: ctx.data.brand.brand,
          model: ctx.data.model.model,
          capacity: ctx.data.capacity.capacity,
          cpu: ctx.data.cpu.name,
          cpu_generation: ctx.data.generation.cpu_generation,
          screen: ctx.data.screen.screen,
          ram: ctx.data.ram.ram,
          name: ctx.data.name,
          lastname: ctx.data.lastname,
          email: ctx.data.email,
          // discount_amount: ctx.data.sbu.id_sbu !== 2 || ([1, 2].includes(ctx.data.brand.id_brand) && ctx.data.sbu.id_sbu === 2) ? ctx.data.model.amount - resultado : ctx.data.capacity.amount - resultado,
          discount_amount: getMonto(ctx.data),
          answers: ctx.data.answers,
          login_code: code
        }

        console.log('obj => ', obj)

        await webApiService.saveData(obj)
        setButtons({ loading: false, success: true })
        enqueueSnackbar('Sus datos fueron enviados. Recibirá un correo', { variant: 'success' })
        setTimeout(() => {
          handleRestart()
        }, 4000)
      } catch (error) {
        setButtons({ ...buttons, loading: false })
        enqueueSnackbar('No fue posible guardar la información del trade-in: ' + error, { variant: 'error' })
      }
    }
  }

  const handleBack = () => {
    saveCtx({ ...ctx, steps: { isValid: true, current: activeStep - 1 } })
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <StepOne />
      case 1:
        return <StepTwo />
      case 2:
        return <StepThree />
      default:
        return 'Redireccionando al agendamiento...'
    }
  }

  return (
    <Box className='position-relative' style={{ zIndex: '1' }}>
      <Container maxWidth='md'>
        <div className='py-5'>
          <div className='row'>
            <div className='col-6 d-flex align-items-center'>
              <div className='text-white'>
                <img src={Safe} alt='' className='w-75 mb-4' />
                <p>Servicio de <strong>Trade In Apple en Chile</strong></p>
                <p>Entrega tu equipo antiguo en parte de pago y ahorra hasta un <strong>55% en la compra de tu nuevo iPhone</strong>.</p>
                <p>Puedes hacerlo en cualquiera de nuestras tiendas.</p>
              </div>
            </div>
            <div className='col-6'>
              <img src={Phones} alt='' className='w-100' />
            </div>
          </div>
        </div>
        <Typography variant='h5' mb={4} className='bg-title rounded-start-pill px-4 text-white py-2 fw-bold'>
          {steps[activeStep]}
        </Typography>
        <Box display='flex' alignItems='center' justifyContent='space-between' mb={2} width='100%'>
          {steps.map((label, index) => (
            <Box key={index} display='flex' flexDirection='column' alignItems='center' width='100%' mx={0.5} mr={1}>
              <Box width='100%' mr={1}>
                <LinearProgress
                  variant='determinate'
                  value={activeStep > index ? 100 : activeStep === index ? 100 : 0}
                  sx={{
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: activeStep > index ? '#A69FF7' : activeStep === index ? '#A69FF7' : 'grey'
                    },
                    backgroundColor: '#fff'
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
        <Box mt={2}>
          {getStepContent(activeStep)}
        </Box>
      </Container>
      <Box mt={5} className='footer' display='flex' justifyContent='center'>
        <Button
          variant='outlined'
          className='me-2 text-white border-white rounded-pill'
          onClick={handleRestart}
          disabled={buttons.loading || buttons.success}
          style={{ marginLeft: '10px', display: activeStep === 2 ? 'block' : 'none' }}
        >
          Volver a empezar
        </Button>
        <Button variant='outlined' className='me-2 text-white border-white rounded-pill' disabled={activeStep === 0 || buttons.success || buttons.loading} onClick={handleBack} style={{ display: activeStep === 0 ? 'none' : 'block' }}>
          Regresar
        </Button>
        <Button
          variant='contained'
          className='bg-primary btn-nextfinish rounded-pill'
          onClick={handleNext}
          disabled={buttons.loading || (ctx.steps.current < 2 && activeStep === ctx.steps.current && !ctx.steps.isValid) || (ctx.steps.current === 2 && ctx.data.email === '') || (ctx.steps.current === 2 && ctx.data.name === '') || (ctx.data.email !== '' && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(ctx.data.email)) || (ctx.steps.current === 2 && buttons.success)}
          style={{ marginLeft: '10px', color: '#fff' }}
        >
          {buttons.loading ? <CircularProgress size={24} /> : activeStep === 2 ? 'Aceptar' : 'Continuar'}
        </Button>

      </Box>
    </Box>
  )
}

const HomePage: React.FC = () => {
  return (
    <HomePageProvider>
      <HomePageContent />
    </HomePageProvider>
  )
}

export default HomePage
